<template>
  <div class="order-info">
    <div v-if="seller" class="info-items">
      <div class="item">
        <div class="title"><b>Продавец:</b></div>
        <div><b>{{ seller.title || seller.fullName }}</b></div>
      </div>
      <div class="item">
        <div class="title">Телефон продавца:</div>
        <div>{{ $n.phoneFormat(seller.phone) }}</div>
      </div>
      <div class="item">
        <div class="title">Адрес продавца:</div>
        <div>{{ $n.size(seller) ? (seller.address || 'Не указан') : '' }}</div>
      </div>
    </div>
    <div v-if="order" class="info-items">
      <div class="item">
        <div class="title"><b>Заказ на имя:</b></div>
        <div><b>{{ order.fullName }}</b></div>
      </div>
      <div class="item">
        <div class="title">Телефон:</div>
        <div>{{ $n.phoneFormat(order.phone) }}</div>
      </div>
      <div class="item">
        <div class="title">Способ оплаты:</div>
        <div>{{ $n.paymentMethod(order.paymentMethod).title }}</div>
      </div>
      <div class="item">
        <div class="title">Способ получения:</div>
        <div>
          {{ $n.deliveryMethod(order.deliveryMethod).title }}
          <template v-if="order.deliveryMethod === 'delivery'">в {{ order.time }}</template>
        </div>
      </div>
      <div v-if="order.deliveryMethod === 'delivery'" class="item">
        <div class="title">Адрес доставки:</div>
        <div>{{ $n.addressInfo(order) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderInfo',
  props: {
    seller: { type: Object, default: () => {}, },
    order: { type: Object, default: () => {}, },
  },
}
</script>

<style lang="scss" scoped>
.order-info {
  .info-items {
    border-bottom: 1px solid #2A82D3;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .item {
      display: flex;
      align-items: flex-start;
      font-size: 13px;
      color: #404040;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .title {
        color: #BABABA;
        min-width: 140px;
        max-width: 140px;
        margin-right: 5px;
      }
    }
  }
}
</style>
