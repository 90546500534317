<template>
  <div class="page-order-create">
    <back />

    <page-title title="Оформление заказа" />

    <div class="content">
      <n-loader :loading="$var('load') || $var('loadCheck')" />
      <div class="container">
        <order-info :seller="cart.seller" />
        <order-products :items="cart.items" from-cart />

        <div class="contact-details">
          <h2>Ваши контактные данные</h2>

          <n-form @submit="submit">
            <n-items>
              <n-input title="Ваше имя" v-bind="$form.input('fullName')" />
              <n-input title="Ваш телефон" v-bind="$form.input('phone')" />
              <n-form-item title="Способ доставки">
                <template v-for="item in $n.deliveryMethod()">
                  <n-radio :key="item.name" :val="item.name" v-bind="$form.input('deliveryMethod')">{{ item.title }}</n-radio>
                </template>
              </n-form-item>
              <transition name="fade" :duration="100">
                <n-items v-if="$form.get('deliveryMethod') === 'delivery'" class="delivery-info">
                  <n-input title="Ваш адрес" v-bind="$form.input('address')" />
                  <div class="address-block">
                    <n-input title="Номер квартиры" v-bind="$form.input('flat')" />
                    <n-input title="Номер подъезда" v-bind="$form.input('door')" />
                  </div>
                  <n-input title="Дополнительная информация" v-bind="$form.input('comment')" />
                  <n-input title="Время" v-bind="$form.input('time')" style="width: 100px" />
                </n-items>
              </transition>
              <n-form-item title="Способ оплаты">
                <template v-for="item in $n.paymentMethod()">
                  <n-radio :key="item.name" :val="item.name" v-bind="$form.input('paymentMethod')">{{ item.title }}</n-radio>
                </template>
              </n-form-item>
              <n-button class="main-btn blue" type="submit">Оформить</n-button>
              <n-link :to="{name: 'cart'}" style="width: 100%">
                <n-button class="main-btn">Отменить заказ</n-button>
              </n-link>
            </n-items>
          </n-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderProducts from '../components/OrderProducts'
import OrderInfo from '../components/OrderInfo'
export default {
  name: 'PageOrderCreate',
  components: { OrderInfo, OrderProducts, },
  data() {
    return {
      cart: {
        seller: {},
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    type() {
      return this.$route.params.type
    },
  },
  created() {
    this.load()
    this.check()
    const user = $app.auth.user()
    this.$form.init({
      sellerId: this.id,
      sellerType: this.type,
      fullName: user.fullName,
      phone: user.phone,
      address: user.address,
      flat: user.flat,
      door: user.door,
      comment: user.comment,
      time: $app.date.format($app.date.now().subtract({ hour: -1, }), 'time'),
      deliveryMethod: 'delivery',
      paymentMethod: 'card',
    })
    this.$form.rules({
      fullName: [ 'required', ],
      phone: [ 'required', ],
      // address: [ 'required', ], // надо сделать обязательным только при доставке
      time: [ 'mask', ], // надо сделать обязательным только при доставке
      deliveryMethod: [ 'required', ],
      paymentMethod: [ 'required', ],
    })
    this.$form.masks({
      time: 'time',
    })
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.cart.getOne(this.id, this.type).then((response) => {
        this.cart = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    check() {
      this.$var('loadCheck', true)
      $api.app.cart.check(this.id, this.type).then((response) => {
      }).finally(() => {
        this.$var('loadCheck', false)
      })
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.app.orders.create(this.$form.get()).then((response) => {
          $app.store.action('app.cartCount')
          this.$router.push({ name: 'messages.success.order', params: { id: response.data.content.id, }, })
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.page-order-create {
  .content {
    padding: 20px 0 0;
  }

  .contact-details {
    padding-top: 16px;

    h2 {
      margin: 15px 0;
    }
  }

  .address-block {
    width: 100%;
    display: flex;
    &>*:not(:last-child) {
      margin-right: 20px;
    }
  }

  .delivery-info {
    width: 100%;
    margin: 0;
    max-height: 380px;
    transition: max-height 80ms;
    &.fade-enter, &.fade-leave-active {
      max-height: 0;
      opacity: 0;
    }
  }

  .main-btn {
    width: 100%;
    border: 1px solid #2A82D3;
    box-sizing: border-box;
    border-radius: 100px;
    font-weight: 500;
    font-size: 12px;
    height: 40px;
    background: #FFF;
    color: #2A82D3;
    transition: all .2s ease;
    position: relative;

    &.blue {
      background: #2A82D3;
      color: #FFF;
    }
  }
}
</style>
