<template>
  <div class="order-products">
    <div v-for="item in items" :key="product(item).id" class="product">
      <div class="avatar">
        <img :src="product(item).avatar" alt="">
      </div>

      <div class="product-info">
        <div>
          <div class="title"><n-link :to="{name: 'products.index', params: { id: item.id, }, }">
            {{ product(item).title }}
          </n-link></div>
          <div v-if="product(item).code" class="code">Артикул: {{ product(item).code }}</div>
          <n-link v-if="canReview && item.storeId && !item.isReviewed" :to="{name: 'reviews.create', query: {product: product(item).id}}" class="review-link">
            Оставить отзыв</n-link>
        </div>
        <div class="bottom">
          <div class="count">{{ info(item).count }} шт х {{ $n.numberFormat(info(item).cost) }} ₸</div>
          <div class="price">{{ $n.numberFormat(info(item).count * info(item).cost) }} ₸</div>
        </div>
      </div>
    </div>

    <div class="total-price">Общая сумма: <b>{{ $n.numberFormat(sum) }} ₸</b></div>
  </div>
</template>

<script>
export default {
  name: 'OrderProducts',
  props: {
    items: { type: Array, default: () => [], },
    fromCart: { type: Boolean, default: false, },
    canReview: { type: Boolean, default: false, },
  },
  computed: {
    sum() {
      return $n.reduce(this.items, (result, item) => {
        const info = this.info(item)
        result += (info.cost * info.count)
        return result
      }, 0)
    },
  },
  methods: {
    product(item) {
      return this.fromCart ? item.product : item
    },
    info(item) {
      return this.fromCart ? {
        count: item.count,
        cost: item.product.cost,
      } : {
        count: item.orderData.count,
        cost: item.orderData.sum / item.orderData.count,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.order-products {
  .product {
    background: #FFF;
    min-height: 94px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    padding: 15px 15px 15px 15px;
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .avatar {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .product-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      .review-link {
        font-size: 12px;
        text-decoration-line: underline;
        color: var(--primary);
      }
    }

    .title {
      font-weight: 500;
      font-size: 18px;
      color: #000;
      margin-bottom: 5px;
      .n-link {
        color: inherit;
        text-decoration: none;
      }
    }

    .code {
      font-size: 12px;
      color: #BABABA;
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #404040;
      border-top: 1px solid #F2F2F2;
      padding-top: 5px;
      margin-top: 8px;

      .price {
        font-weight: 700;
      }
    }
  }

  .total-price {
    font-size: 14px;
    color: #BABABA;
    text-align: right;
    margin-top: 10px;

    b {
      font-size: 16px;
      color: #404040;
      margin-left: 10px;
    }
  }
}
</style>
